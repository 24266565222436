import React, { Component } from 'react';

import Heading2 from '../../Atoms/Heading/Heading2';

import './index.scss';
import Map from '../../Molecules/Map';
import Instagram from '../../../assets/images/social/instagram.png';

class Location extends Component {
  render() {
    return (
      <section className="panel">
        <div className="location">
          <div className="location-item">
            <Heading2>Localização</Heading2>

            <p>
              Rua Heróis de França, 617
              <br />
              Matosinhos, Porto 4450-159
            </p>

            <div className="second-heading">
              <Heading2>Horários</Heading2>
            </div>

            <p>
              Segunda <br />
              Fechado
            </p>

            <p>
              Terça a Quinta <br />
              16h - 23h
            </p>

            <p>
              Sexta <br />
              16h - 0h
            </p>

            <p>
              Sábado <br />
              14h - 0h
            </p>

            <p>
              Domingo
              <br />
              14h - 22h
            </p>

            <p>
              Horário diferenciados para feriados.
              <br />
              Para maiores informações consulte nosso <a href='https://www.instagram.com/hoptripcraftbeer' className="instagram-text" target="_blank">Instagram</a>.
            </p>
          </div>

          <div className="location-item location-map">
          <Map />
          </div>
        </div>
      </section>
    );
  }
}

export default Location;
