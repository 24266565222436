import React, { Component } from 'react';

import Section from '../../Molecules/Section';
import PetFriendly from '../../../assets/images/sections/pet_friendly.png';

class Sections extends Component {
  render() {
    return (
      <section className="panel">
        <div className="sections">
          <Section title="Pet Friendly" image={PetFriendly}>
            <p>
              Na HopTrip a nossa responsável por receber os pets é a nossa
              adorável Spuma Puro Malte. Os seus animais de estimação são
              bem-vindos aqui.
            </p>
            <p>Porque os melhores amigos podem vir juntos à HopTrip! </p>
          </Section>
        </div>
      </section>
    );
  }
}

export default Sections;
